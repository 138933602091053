const productData = {
  1: {
    id: 1,
    name: "INSTANT CASH",
    img1: require("../assets/img/products/instantCash.png"), // Ruta de la imagen 1
    img2: require("../assets/img/products/instantCash2.jpg"), // Ruta de la imagen 2
    videoSrc: require("../assets/img/products/instantCash.mp4"), // Ruta del video
    description: `What is Instant Cash? 
    INSTANT CASH is a brand new visual effect  that allows you to transform your card holder wallet into real money.

    Features:

    - Easy to do. 
    - Instant reset. 
    - Fits in your pocket.
    - Designed to use with real bills.
    - No funny moves. 
    - No- elastics.

    - Instant Cash comes with everything you need and it is always ready to perform.
    - You can perform Instant Cash in close-up, street and stage magic.`, // Descripción del producto
    price: "$ 00.00", // Precio del producto
  },

  2: {
    id: 2,
    name: "SPIKE TELEPORT SYSTEM",
    img1: require("../assets/img/products/SPIKE_TELEPORT_SYSTEM_IMAGE.png"), // Ruta de la imagen 2
    img2: require("../assets/img/products/STS_IMAGE.png"), // Ruta de la imagen 1
    videoSrc: require("../assets/img/products/SpikeTeleportSystem.mp4"), // Ruta del video
    description: `- Show every item from the beginning to be examined
    - The Spike is examined by the audience
    - The blocks are examined by the audience
    - The bags are empty and can be examined by the audience before start
    - The participant can load the bags with the blocks and see inside of each bag to confirm everything is fair.
    - The participant can mix the bags
    - The participant can freely choose which bag you smash
    - After smashing the bags you can open them and show that only the blocks are found.
    - No sleeves needed
    - No palming
    - No loads
    - No magnets
    - No marks
    - No vibration
    - No electronics
    - You never retire the spike from the block once its placed into the bag
    - No funny moves
    - Reset in seconds
    - Brand new secure system
    - 100% safe`, // Descripción del producto
    price: "$ 250.00", // Precio del producto
  },

  3: {
    id: 3,
    name: "PRO DECK SWITCH",
    img1: require("../assets/img/products/PRO_DECK_SWITCH_PICTURE_1.png"), // Ruta de la imagen 1
    img2: require("../assets/img/products/PRO_DECK SWITCH_PICTURE.jpg"), // Ruta de la imagen 2
    videoSrc: require("../assets/img/products/PRO_DECK_SWITCH_PERFORMANCE.mp4"), // Ruta del video
    description: `Imagine a deck switch where you NEVER TOUCH the deck to do the switch!

    Pro Deck Switch by Pierre Velarde takes the deck switch to a whole new level.
    
    In fact, there is NO manipulation required. You will never leave the house without this incredible tool.
    Easy to do, with NO visible or complicated moves.
    
    Take a deck of cards, fan them to show all the cards are different. Have 2 spectators each freely choose their own cards and sign them. The spectator's individually place their cards back into the deck.
    
    Incredibly, you are not only able to correctly find each spectator's signed cards, but you turn the rest of the cards into blank faces! Hand the cards out for examination. So easy to do but fun to play with...it is criminal.
    
    But you will leave no fingerprints behind, because you never touch the deck for the switch. The gimmick does all the work for you. High quality gimmick can be used with Bicycle decks
    
    Find 2 separate spectator's cards in a unique and memorable way!
    
    Hand the cards out for spectator examination
    Perform 360 degrees, surrounded
    NO visible or complicated moves
    NO manipulation, NO elastics
    Easy to perform`, // Descripción del producto
    price: "$ 49.95", // Precio del producto
  },

  4: {
    id: 4,
    name: "SPECTRO TOUCH",
    img1: require("../assets/img/64700-full.png"), // Ruta de la imagen 1
    img2: require("../assets/img/spectro.jpg"), // Ruta de la imagen 2
    videoSrc: require("../assets/img/pierre.mp4"), // Ruta del video
    description: `The PK touch is one of mentalism's most powerful effects, where a spectator feels they're being touched while the performer is some distance away.
      Now the performer can perform this effect standing up, using a 100% self-contained diabolical method, at a moment's notice, and at a distance of up to 20 meters.
      João Miranda joined forces with Pierre Velarde, a famous mentalist from Peru, who kept his secret underground since he created it over 15 years ago. With it, he won first prize in the invention category.
      - NO VIBRATIONS
      - NO MAGNETS
      - NO THREADS
      - NO STOOGES
      - NO SPECIAL CHAIRS
      Some of the special features of Spectro Touch are:
      - Small gimmick that you can easily palm and activate thanks to the included custom remote control
      - No vibrations or threads are used at anytime
      - Programmable time delay from the moment the remote is pressed to the gimmick activation
      - VOR technology, a João Miranda well-known staple in every electronic device his company produces. When the remote is pressed, it vibrates, alerting the performer that the button was really pushed, and the radio wave was sent
      - Both the remote and gimmick are USB rechargeable
      - Battery level indication of both the remote and gimmick
      - One charge lasts for 500+ performances
      Now get the best PK touch available on the market, made by professionals for professionals.`, // Descripción del producto
    price: "$ 199.00", // Precio del producto
  },

  5: {
    id: 5,
    name: "GENIOUS PAD",
    img1: require("../assets/img/products/genious.png"), // Ruta de la imagen 1
    img2: require("../assets/img/spectro.jpg"), // Ruta de la imagen 2
    videoSrc: require("../assets/img/products/GENIOUSPAD.mp4"), // Ruta del video
    description: `- Remote control activated and under your control at all times.
    - 20 meter range.
    - Pre-program the effects into the Genious Pad.
    - Save different programs for different effects.
    - Control the effects in real time.
    - Instant reset
    - Rechargeable battery
    - Smooth and quiet operation
    - 100% self-contained.
    - Perfect for Virtual, Close up, Parlor and Stage magic.
    - You can carry your magic items (decks, coins, bills, pocker chips, wand) inside the Genious Pad.
    - You can use it as a normal close-up pad if you want.
    - Light weight and packs flat for travel.`, // Descripción del producto
    price: "$ 699.00", // Precio del producto
  },

  6: {
    id: 6,
    name: "MASTER TABLE PREDICTION",
    img1: require("../assets/img/products/MasterTablePrediction.png"), // Ruta de la imagen 1
    img2: require("../assets/img/products/MasterTablePrediction2.png"), // Ruta de la imagen 2
    videoSrc: require("../assets/img/products/VF-Master-Table-Prediction-Pierre-Velarde.mp4"), // Ruta del video
    description: `- You can predict anything you want
    - You can predict words, numbers, draws, etc.
    - Use any piece of paper
    - The spectators writes anything they want.
    - The papers are in full view all the time
    - There are not switch of any paper
    - The magician never touch the papers 
    - At the end the spectator choose  the any  paper 
    - The prediction is 100% correct
    - Spectators do everything for you.
    
    Features:
    
    - Invisible contained method 
    - Under your complete control at all time 
    - Use a small pocket remote control 
    - High quality table.`, // Descripción del producto
    price: "$ 2,950.00", // Precio del producto
  },
  
  7: {
    id: 7,
    name: "WALLET PREDICTION SYSTEM",
    img1: require("../assets/img/products/wallet1.png"), // Ruta de la imagen 1
    img2: require("../assets/img/products/wallet2.png"), // Ruta de la imagen 2
    videoSrc: require("../assets/img/products/WalletPredictionSystem.mp4"), // Ruta del video
    description: ``, // Descripción del producto
    price: "$ 250.00", // Precio del producto
  },
};

export default productData;
